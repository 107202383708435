module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.1/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.14.0_a09afa2bbfc527e9df6b03af7da50c00/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://benyakiredits.com/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.14.0_2876d9e46dc0fc7c3e84795da1f904ab/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Benyakir Writes","short_name":"Ben Writes","start_url":"/","background_color":"#000","theme_color":"#fff","display":"standalone","icon":"src/files/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e986765a838c4faa272a607d98fb0088"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-posthog@1.0.1_74cbede6f92cedd57a8ce1e06e7830c8/node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_JkVeF41UCd5V9vbxWbLDPvZwY79SrAc3asdhyeZSLJm","apiHost":"https://us.i.posthog.com","head":true,"isEnabledDevMode":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_react-dom@18.3.1+react@18.3.1/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
